@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Poppins:wght@400;500;700&display=swap);
.mainContainer {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	height: 100vh;
}

.logoImg {
	width: 140px;
	height: 32px;
	object-fit: contain;
}

.astronautImg {
	width: 150px;
	height: 150px;
	padding-top: 5%;
	padding-left: 3%;
	object-fit: contain;
	-webkit-flex: 6 1;
	        flex: 6 1;
}

.title {
	font-size: 32px;
	font-weight: bold;
	padding-top: 14%;
	font-family: Poppins, serif;
}

.containerText {
	line-height: 1.5;
	text-align: center;
	padding-left: 15%;
	padding-right: 15%;
}

.subtitle {
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0.02px;
}

.buttonGoBack a {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	text-decoration: none;
	color: #ffffff;
	padding: 8px 16px;
	width: 170px;
	height: 40px;
	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(230, 30, 77, 0.16);
	background-color: #e61e4d;
	line-height: 1.5;
	letter-spacing: 0.2px;
	font-family: Inter, serif;
	font-size: 16px;
	font-weight: bold;
	margin: 12px;
}

.opacityText {
	font-family: Inter, serif;
	font-size: 16px;
	font-weight: normal;
	letter-spacing: 0.08px;
	color: #757575;
}

.redirect {
	font-family: Inter, serif;
 	font-size: 16px;
	font-weight: normal;
	margin-top: 6%;
	color: #212121;
}

.containerFooter {
	width: 90%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: center;
	        align-items: center;
	padding-left: 3%;
	padding-bottom: 5%;
	padding-top: 7%;
}

.containerHeader {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	padding: 32px;
}

.containerBody {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	text-align: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
}

.containerContent {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

@media (min-width: 767px) {
	.containerHeader {
		width: 90%;
		padding-top: 32px;
		padding-left: 104px;
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
	}
	.containerBody {
		-webkit-flex-direction: row;
		        flex-direction: row;
		padding-top: 3vh;
	}
	.containerContent {
		-webkit-flex: 5 1;
		        flex: 5 1;
		-webkit-align-items: flex-start;
		        align-items: flex-start;
	}
	.subtitle {
		text-align: start;
		font-size: 20px;
	}
	.opacityText {
		text-align: start;
		font-size: 16px;
	}
	.containerText {
		padding: 0 50% 0 0;
	}
	.astronautImg {
		width: 468px;
		height: 468px;
		padding-top: 0;
	}
	.title {
		font-size: 56px;
		padding-top: 0;
	}
	.containerFooter {
		padding-bottom: 40px;
	}
	.buttonGoBack a {
		margin: 0;
	}
}

